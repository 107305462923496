import { useRouter } from 'apis/history';
import { Seo, Text } from 'components';

import Header from 'components/Header';
import SocialRow from 'components/SocialRow';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { mobile, useQuery } from 'styles/breakpoints';
import Person1 from '../../assets/images/teamMembers/Kazys.png';
import Person2 from '../../assets/images/teamMembers/Jacob.png';
import Person3 from '../../assets/images/teamMembers/Thomas.png';

import LinkedIn from '../../assets/icons/linkedin.svg';
import SMS from '../../assets/icons/sms.svg';
import Location from '../../assets/icons/location.svg';
import Home from '../../assets/icons/home.svg';
import Call from '../../assets/icons/call.svg';
import Zoom from '../../assets/icons/Zoom.png';
import Social from '../../assets/icons/Socials.png';
import Footer from 'components/Footer';
import Overlay from 'pages/start/quiz/components/Overlay';
import { config } from 'config';

const MainContainer = styled.div`
  width: 100vw;
  background-size: cover;
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;
  align-items: center;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${mobile} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  margin: 0 1.5rem;
  white-space: nowrap;
`;

const TitleContainer = styled.div`
  padding: 3.813rem 0 2rem;
  display: flex;
  justify-content: center;
  @media ${mobile} {
    padding: 24px 0;
  }
`;

const StyledTitle = styled(Text)`
  font-size: 2.375rem;

  @media ${mobile} {
    font-size: 1.625rem;
  }
`;

const TeamSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
const SubTitle = styled(Text)`
  font-size: 1.125rem;
  margin-bottom: 4.5rem;
  @media ${mobile} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

const TeamWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media ${mobile} {
    flex-direction: column;
  }
`;

const ImageWrap = styled.div``;

const ImageNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 38px 40px;
`;

const Name = styled(Text)`
  font-size: 1.25rem;
  font-weight: 600;
`;

const Role = styled(Text)``;

const Logo = styled.img`
  height: 192px;
  width: 192px;
  border-radius: 50%;
  margin: 0 auto 39px;
  @media ${mobile} {
    height: 240px;
    width: 240px;
  }
`;

const ContactSection = styled.div`
  display: flex;
  margin: 0 1rem;
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  justify-content: space-between;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const LeftSide = styled.div``;

const LeftSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const LinkedInLogo = styled(LinkedIn)`
  height: 32px;
  width: 32px;
  margin-right: 0.5rem;
`;

const CallLogo = styled(Call)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const HomeLogo = styled(Home)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const LocationLogo = styled(Location)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const SmsLogo = styled(SMS)`
  height: 32px;
  width: 32px;
  margin-right: 1.5rem;
`;

const NameWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ItemWrap = styled.div`
  display: flex;
  padding-bottom: 2rem;
`;

const TextWrap = styled.div``;

const Title = styled(Text)`
  font-size: 1.125rem;
  font-weight: 600;
`;

const LeftSubTitle = styled(Text)`
  padding-top: 0.688rem;
`;

const RightSide = styled.div``;

const RightSideTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.875rem;
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const CardSection = styled.div`
  margin: auto;
  max-width: 100%;
  padding: 0 32px;
  width: 1204px;
  padding: 4.5rem 0;
  @media ${mobile} {
    padding: 28px 0 0;
  }
`;

const CardSectionWrap = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mobile} {
    flex-direction: column;
  }
`;

const LeftCard = styled.div`
  background: #e5f0ff;
  border-radius: 16px;
  flex-grow: 1;
  overflow: hidden;
  padding: 48px 48px 176px;
  position: relative;
  @media ${mobile} {
    border-radius: 0px;
    padding: 48px 16px 228px;
  }
`;

const LeftCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45.8%;
  @media ${mobile} {
    width: 100%;
  }
`;

const RightCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 45.8%;

  @media ${mobile} {
    width: 100%;
  }
`;

const RightCard = styled.div`
  background: #e6ffff;
  border-radius: 16px;
  flex-grow: 1;
  overflow: hidden;
  padding: 48px 48px 176px;
  position: relative;
  @media ${mobile} {
    border-radius: 0px;
    padding: 48px 16px 228px;
  }
`;

const ZoomImage = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
`;

const SocialImage = styled.img`
  bottom: 0;
  position: absolute;
  right: 0;
`;

const CardTitle = styled(Text)`
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 21px;
`;

const BookButton = styled.a`
  align-items: center;
  background: #1c1c28;
  border: 0;
  color: #fff !important;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  margin-top: 31px;
  position: relative;
  vertical-align: top;
  cursor: pointer;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  @media ${mobile} {
    width: 100%;
  }
`;

const Contact = () => {
  const [show, setShow] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: '',
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { isMobile } = useQuery();

  const updateInput = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { goToContact, goToReviews, goToFAQ, goToStarterGuide, goToStory } =
    useRouter();

  const handleSubmit = event => {
    event.preventDefault();

    if (formData.honeypot === '') {
      sendEmail();
    } else {
      setError(true);
    }
  };

  const sendEmail = async () => {
    try {
      setLoading(true);
      await fetch(config.FIREBASE_MAILING, {
        method: 'POST',
        body: JSON.stringify({
          email: formData.email,
          name: formData.name,
          message: formData.message,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      setFormData({
        name: '',
        email: '',
        message: '',
        honeypot: '',
      });
      setError(false);
      setSuccess(true);
    } catch {
      setSuccess(false);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        <StyledContainer onClick={() => goToStory()}>
          <Text type="body500">Our Story</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToReviews()}>
          <Text type="body500">Reviews</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToFAQ()}>
          <Text type="body500">FAQ</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStarterGuide()}>
          <Text type="body500">Starter Guide</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToContact()}>
          <Text type="body500">Contact Us</Text>
        </StyledContainer>
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isMobile && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );

  const renderBottomComponent = () => {
    if (success) {
      return <SuccessText>Message was sent successfully!</SuccessText>;
    }
    if (error) {
      return (
        <>
          <ErrorText>There was an issue sending your email.</ErrorText>
          <StyledButton type="submit">Submit</StyledButton>
        </>
      );
    }
    return (
      <StyledButton disabled={loading ? true : false} type="submit">
        Submit
      </StyledButton>
    );
  };

  return (
    <>
      <Seo title="Effecto | Contact Us" />
      <MainContainer className={show ? 'active-menu' : ''}>
        <Header
          logoVariant="left"
          sticky={true}
          RightComponent={renderRightComponent()}
          color="light0"
        />
      </MainContainer>

      {show && (
        <Overlay
          zIndex={1}
          navLinks={[
            { title: 'Our Story', url: 'https://effecto.health/story' },
            { title: 'Reviews', url: 'https://effecto.health/reviews' },
            { title: 'FAQ', url: 'https://effecto.health/faq' },
            { title: 'Starter Guide', url: 'https://effecto.health/single' },
            { title: 'Contact Us', url: 'https://effecto.health/contact' },
          ]}
          socialLinks={[
            { svgId: 'f-reddit', url: 'https://www.reddit.com/r/EffectoApp/' },
            { svgId: 'f-tw', url: 'https://twitter.com/EffectoApp' },
            { svgId: 'f-fb', url: 'https://www.facebook.com/TheEffectoApp' },
            {
              svgId: 'f-instagram',
              url: 'https://www.instagram.com/effecto.app/',
            },
          ]}
        />
      )}

      <TitleContainer>
        <StyledTitle>
          Hello, how <strong>can we help you?</strong>
        </StyledTitle>
      </TitleContainer>
      <TeamSection>
        <SubTitle>Meet our team members</SubTitle>
        <TeamWrap>
          <ImageNameWrap>
            <Logo src={Person1} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Kazimieras Melaika</Name>
            </NameWrap>
            <Role>Medical</Role>
          </ImageNameWrap>

          <ImageNameWrap>
            <Logo src={Person2} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Jokūbas Lapė</Name>
            </NameWrap>
            <Role>Product</Role>
          </ImageNameWrap>

          <ImageNameWrap>
            <Logo src={Person3} />
            <NameWrap>
              <LinkedInLogo />
              <Name>Tomas Butkevičius</Name>
            </NameWrap>
            <Role>Marketing</Role>
          </ImageNameWrap>
        </TeamWrap>
      </TeamSection>

      <ContactSection>
        <LeftSide>
          <LeftSideTitle>Get in touch with us</LeftSideTitle>
          <ItemWrap>
            <HomeLogo />
            <TextWrap>
              <Title>Health Score Inc.</Title>
              <LeftSubTitle>Our Company</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <CallLogo />
            <TextWrap>
              <Title>+1 415 882 6760</Title>
              <LeftSubTitle>Phone number</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <SmsLogo />
            <TextWrap>
              <Title>help@effecto.app</Title>
              <LeftSubTitle>Email address</LeftSubTitle>
            </TextWrap>
          </ItemWrap>

          <ItemWrap>
            <LocationLogo />
            <TextWrap>
              <Title>100 Pine Street, Suite 1250</Title>
              <LeftSubTitle>San Francisco, CA 94111</LeftSubTitle>
            </TextWrap>
          </ItemWrap>
        </LeftSide>
        <RightSide>
          <RightSideTitle>Leave us a message</RightSideTitle>
          <form onSubmit={handleSubmit}>
            <StyledLabel>
              Your email address <StyledStar>*</StyledStar>
            </StyledLabel>
            <HoneyPot
              name="honeyPot"
              onChange={updateInput}
              value={formData.honeyPot || ''}
            />
            <StyledInput
              type="email"
              name="email"
              required={true}
              onChange={updateInput}
              value={formData.email || ''}
            />

            <StyledLabel>
              Subject <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledInput
              type="text"
              name="name"
              required={true}
              onChange={updateInput}
              value={formData.name || ''}
            />

            <StyledLabel>
              Description <StyledStar>*</StyledStar>
            </StyledLabel>
            <StyledTextArea
              name="message"
              onChange={updateInput}
              value={formData.message || ''}
            />

            <Note>
              Please enter the details of your request. A member of our team
              will respond as soon as possible.
            </Note>
            {renderBottomComponent()}
          </form>
        </RightSide>
      </ContactSection>

      <CardSection>
        <CardSectionWrap>
          <LeftCardContainer>
            <LeftCard>
              <ZoomImage src={Zoom} />
              <CardTitle>Book a live onboarding session</CardTitle>
              <Text>
                We will set you up for a successful start with Effecto.
              </Text>
              <br />
              <Text>
                The ideas and concerns of our community is the no. 1 priority
                for us. Tell us what you think about the app and we promise to
                include your ideas in the future updates!
              </Text>
              <BookButton href="https://calendly.com/d/djx-jht-fpy/effecto-onboarding-call">
                Book
              </BookButton>
            </LeftCard>
          </LeftCardContainer>
          <RightCardContainer>
            <RightCard>
              <SocialImage src={Social} />
              <CardTitle>Join our community</CardTitle>
              <StyledText>
                Read inspiring success stories, get useful advices, share your
                own thoughts and celebrate achievements by joining the Effecto
                community on both Facebook and reddit!
              </StyledText>
              <SocialRow />
            </RightCard>
          </RightCardContainer>
        </CardSectionWrap>
      </CardSection>
      <Footer />
    </>
  );
};

export default Contact;

const HoneyPot = styled.input`
  display: none;
`;

const StyledStar = styled.span`
  color: #e53535;
`;

const SuccessText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rgb(6, 194, 112);
`;

const ErrorText = styled(Text)`
  padding-bottom: 12px;
  font-size: 0.9rem;
  color: rrgb(255, 59, 59);
`;

const Note = styled.div`
  color: #1c1c28;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin: -20px 0 47px;
  opacity: 0.8;
`;

const StyledButton = styled.button<{ disabled?: boolean }>`
  align-items: center;
  background: ${({ disabled }) => (disabled ? '#EBEBF0' : '#1c1c28')};
  border: 0;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  letter-spacing: -0.024em;
  min-height: 48px;
  min-width: 240px;
  padding: 14px 16px;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  cursor: ${({ disabled }) => (disabled ? 'none' : 'pointer')};
`;

const StyledLabel = styled.label`
  color: #1c1c28;
  display: block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.024em;
  line-height: 140%;
  margin-bottom: 12px;
`;

const StyledInput = styled.input`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  -webkit-appearance: none;
`;

const StyledTextArea = styled.textarea`
  border-radius: 8px;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  background: #fff;
  border: 1px solid #e4e4eb;
  color: #1c1c28;
  display: block;
  height: 48px;
  margin: 0 0 32px;
  outline: none;
  padding: 8px 16px;
  width: 100%;
  min-height: 144px;
`;

const StyledText = styled(Text)`
  margin-bottom: 2rem;
`;
